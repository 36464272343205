<template>
  <div class="Sociology-view1-main">
    <div class="Sociology-view1-content">
      <div v-for="(item,index)  in showData" :key="index" @click="item.show = !item.show">
        <el-row class="Sociology-view1-content-item">
          <el-col :span="20">
            <div class="Sociology-view1-content-item-title">
              <div style="padding: 40px 60px">
                <span class="text1">{{ item.title }}</span>
              </div>
              <div style="padding: 0px 60px 20px 60px">
                <span class="text2" v-if="!item.show">岗位职责：{{ item.info }}</span>
                <div class="text2 flex align-items-center" v-else>
                  <img src="../../../assets/images/Campus/u2741.svg" alt="">
                  <span class="ml5">岗位咨询电话： 176-6095-8313（王女士）</span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="Sociology-view1-content-item-img">
              <img src="../../../assets/images/Project/01_u416.svg"/>
            </div>
          </el-col>
        </el-row>
        <div class="Sociology-view1-content-item-body" v-if="item.show">
          <h4>岗位职责</h4>
          <ul>
            <li v-for="(duty,index) in item.duty" :key="index">
              {{ duty }}
            </li>
          </ul>
          <h4>岗位要求</h4>
          <ul>
            <li v-for="(requirements,index) in item.requirements" :key="index">
              {{ requirements }}
            </li>
          </ul>
          <div class="go-title" v-if="item.show" @mouseover="item.show = true">
            <div>立即沟通</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "view1",
  data() {
    return {
      active: 0,
      showData: [
        {
          title: '销售经理人（城市经理人）',
          info: '负责所在区域新老客户的信息收集拜访及分析… …',  // 暂缺
          show: false,
          duty: [
            '负责所在区域新老客户的信息收集拜访及分析；开拓指定行业或区域内价值客户及潜在客户，熟悉客户运作流程，与客户建立良好关系；',
            '根据公司下达的销售任务，参与制定全年及月度相应销售计划，包括拜访计划、销售目标分解计划、销售回款计划、销售预测计划等，并在销售活动中严格执行公司销售计划，完成公司的市场活动及目标；',
            '适应长期出差，区域客户关系维护，寻找与跟踪客户项目,进行商务谈判、组织与协调研发配合及项目管理推进；',
            '有效完成大区经理安排的各项指定任务及协调事宜任职资格。负责所在区域新老客户的信息收集拜访及分析；',
            '完成公司的市场活动及目标；',
          ],
          requirements: [
            '本科及以上学历。',
            '可具备行业知识但不是必须。',
            '乐观、积极主动，有良好的团队意识。',
          ]
        },
        {
          title: '项目经理人（内部项目跟进）',
          info: '根据市场及客户需求，向公司及时、准确、高效的反馈相关信息并提分供相关技术支持；',
          show: false,
          duty: [
            '根据市场及客户需求，向公司及时、准确、高效的反馈相关信息并提分供相关技术支持；',
            '负责对接需求产品市场销售过程的风险、问题的识别、推动及跟踪闭环；',
            '负责内外部与项目有关的事项的沟通协调；',
            '负责组织相关项目里程碑会议及例会，发布项目日常报告；',
            '负责项目开展过程中的质量监控工作，对研发过程质量进行监控，定期分析过程质量数据，发现问题并跟踪闭环；',
            '负责推动产品市场问题的根因分析、组织制定纠正预防措施并跟踪闭环；'
          ],
          requirements: [
            '有3年以上软件/硬件产品开发项目管理类工作经验，本科及以上学历；',
            '熟悉产品生命周期管理；',
            '具备较强的用户体验意识，较好的沟通、交流能力，具备系统性思维能力；',
            '有较强的系统分析能力和组织、学习、沟通、理解能力，积极主动。',
            '具备较强的工作责任心和团队协作精神。'
          ]
        },
        {
          title: '市场总监',
          info: '根据公司发展目标及战略，策划公司产品和品牌的定位… …',
          show: false,
          duty: [
            '根据公司发展目标及战略，策划公司产品和品牌的定位，制订有效的市场推广策略；',
            '规划和拓展市场渠道，实现公司整体的市场拓展目标；',
            '负责品牌对外推广和宣传，制定各种整合营销的活动，对危机事件做出有效反应；',
            '制定年度市场活动预算与计划，监督实施并评估市场效果与预算执行情况；',
            '开拓并管理媒体和供应商渠道，保持与传统媒体和新媒体(含自媒体)的良好关系；',
            '围绕项目营销策划或活动组织，负责公司外部资源整合，保持与政府资源、大型企业集团资源、院校资源等关系维护；',
          ],
          requirements: [
            '本科及以上学历，市场营销、传媒、公共关系、新闻等相关专业；',
            '有3-5年市场策划、商务公关及品牌推广运营工作经验；',
            '极具创新意识，敏锐的市场洞察力，具备逻辑分析能力，善于利用数据对用户需求及行为、产品发展等进行深入；',
            '拥有丰富的平台和媒体资源，能保持良好的合作关系；',
            '较强的市场敏锐度，对市场营销有深刻的理解和认知；',
            '有较强的活动执行能力，良好的沟通能力和积极向上的团队精神；',
            '熟悉专业创意方法，思维敏捷，洞察力强，细心，耐心，语言表达能力强；',
          ]
        },
        {
          title: '暖通工程师',
          info: '根据客户图纸，出具对应的暖通方案,详细计算冷热负荷量',
          show: false,
          duty: [
            '根据客户图纸，出具对应的暖通方案；',
            '详细计算冷热负荷量；',
            '撰写暖通方案书；',
            '方案通过后，设计施工图；',
            '能适应偶尔出差，配合市场给客户讲解方案；',
            '可以短期出差，是施工现场遇到的问题给予指导；',
          ],
          requirements: [
            '本科及以上学历；',
            '精通CAD；',
            '熟练使用至少一款三维设计软件；',
            '良好的沟通能力.'
          ]
        },
        {
          title: '外贸经理/专员',
          info: '确保及时有效的与客户进行沟通联系，及时处理客户邮件，并负责国外客户的接待商谈等事宜',
          show: false,
          duty: [
            '确保及时有效的与客户进行沟通联系，及时处理客户邮件，并负责国外客户的接待商谈等事宜；',
            '协调客户和生产以及采购、技术等部门的关系，以确保每一个订单都能保质保量如期交货，可独立处理外贸销售中的相关问题；',
            '订单的接收和安排，跟踪订单的生产进度，出现状况及时协调解决，确认交期并反馈客户；',
            '通过系统或者邮件提交booking订舱，收到入货通知以后制作封箱单，负责确认样品&生产样品的安排及包装发运，大货验货安排，订舱发运制单等全程出口工作；',
            '制作预付款发票，截单表提交给代理，以及报关单据，七联发票，清关单据等单据的制作及银行交单等；',
            '处理客户投诉等相关问题，和相关部门落实后，将处理方案反馈给客户；',
            '定期统计客户付款，并定期催款，收汇。'
          ],
          requirements: [
            '本科及以上学历，3年以上外贸相关工作经验；',
            '工作仔细认真，态度端正；',
            '熟悉了解进出口货物发货流程及相关手续办理；',
            '有处理突发事件的能力；',
          ]
        },
        {
          title: '高级图像工程师',
          info: '从事图像识别、图像检索、图像匹配等领域的算法研发、实现',
          show: false,
          duty: [
            '从事图像识别、图像检索、图像匹配等领域的算法研发、实现；',
            '主导深度学习相关业务；',
            '负责神经网络算法的设计、调参和优化；',
          ],
          requirements: [
            '硕士及以上学历，计算机软件、图像、数学相关专业，有3年以上图像处理相关经验者优先考虑，有具体生产项目落地者优先考虑；',
            '熟悉C/C++、Python，有较强的工程实现能力；',
            '熟悉TensorFlow、PyTorch等深度学习框架，熟悉OpenCV图像处理框架；',
            '熟悉图像处理算法（去噪、增强、识别、特征提取）；',
            '熟悉图像理解技术（分类、检测、跟踪、分割）；',
            '熟练物体（车辆、行人、人脸、通用目标）检测、跟踪与识别算法；',
            '对主流计算机图像识别算法具有深入了解，具有OpenCV等开发经验优先；',
            '具有数字图像取证分析等领域的算法研发经验者优先；'
          ]
        },
        {
          title: '高级JAVA开发工程师',
          info: '参与项目和产品重要模块的研发工作，负责项目重要逻辑代码编写；',
          show: false,
          duty: [
            '参与项目和产品重要模块的研发工作，负责项目重要逻辑代码编写；',
            '根据业务需求配合撰写系统设计文档，并根据文档完成重要功能开发；',
            '指导初级工程师进行系统开发，并确保开发规范执行；',
            ' 配合解决组内遇到的重要问题及技术难题。',
          ],
          requirements: [
            '本科及以上学历，计算机相关专业毕业；3年以上Java开发经验；',
            '熟悉JavaEE技术平台， java基础知识扎实，包括多线程、集合、缓存、消息队列等；',
            '熟悉主流开源框架（如Spring/SpringMVC、iBatis/MyBatis、Dubbo等），并对其架构设计及实现有一定了解；',
            '熟练使用springboot，熟悉springboot的基本原理；熟悉springcloud全家桶；',
            '熟悉MySql等主流数据库，并具有一定的SQL优化经验；',
            '熟练使用redis、kafka、zookeeper等常用中间件；',
            '具有1个以上的大型Java软件项目设计经验；能独立完成功能开发，并指导组内其他人员进行软件开发的能力，保证软件质量；',
            '熟悉Tomcat等应用服务器和中间件的使用，熟悉Linux系统使用者优先；',
            '学习能力强，具备良好的问题分析和解决能力，愿意并致力于新技术研究；',
            '沟通交流能力强，乐于分享，良好的团队合作精神；',
            '熟悉MES、ERP系统，有相关开发经验者优先。'
          ]
        },
        {
          title: '研发硬件项目负责人',
          info: '负责方案评估，器件选型，BOM输出，成本控制，同时负责风险控制、质量控制',
          show: false,
          duty: [
            '负责方案评估，器件选型，BOM输出，成本控制，同时负责风险控制、质量控制；',
            '负责原理图，PCB layout设计及电路仿真；',
            '负责产品调试、问题解决；',
            '负责新产品导入，旧产品维护；',
            '完成领导安排的其他工作。',
          ],
          requirements: [
            '电子、自动化、通信类相关专业本科及以上学历；',
            '具有2年以上硬件设计经验，能独立承担产品硬件设计；',
            '熟悉模拟、数字电路等基础电路知识，熟悉MCU、ARM类产品内部结构及相关应用；',
            '熟练应用专业PCB绘图软件，通用的电路仿真软件及通用测试仪器仪表； 5.团队合作意识强，有EMC设计整改经验者优先。',
          ]
        },
        {
          title: '研发软件项目负责人',
          info: '根据公司制定的研发计划进行产品应用软件的开发、软件调试及维护 ',
          show: false,
          duty: [
            '根据公司制定的研发计划进行产品应用软件的开发、软件调试及维护。 ',
            '产品开发前期及过程中参与产品开发方案的制定。 ',
            '产品开发完成导入生产前需制定好测试方案及工具。',
            '主动分析并解决开发过程中的问题提高软件代码质量。 ',
          ],
          requirements: [
            '本科以上学历；',
            '熟练使用C语言编程；',
            '精通STM32 等MCU的开发；',
            '熟悉模电知识、熟悉硬件电路板故障排除，能看懂原理图；',
            '能适应短期出差；',
            '熟悉数字电路和模拟电路设计，熟悉至少一种原理图设计软件的使用；',
            '熟悉uCOS,uCGUI优先；'
          ]
        },
        {
          title: '法务主管',
          info: '及时审核业务合同，处理公司内部法务相关系统流程',
          show: false,
          duty: [
            '及时审核业务合同，处理公司内部法务相关系统流程; ',
            '就公司及各部门提出的法律问题，出具法律意见。组织或参与重大合同的谈判、签约等工作； ',
            '负责公司合同文本、协议等尤其涉及版权、知识产权相关的法律文书的起草、修改及各类合同的审查、管理和执行监督工作，合理控制和防范合同管理中心的法律风险；',
            '对公司合同管理工作进行业务指导和监督，并对管理工作中存在的问题提出改进建议；',
            '负责建立、健全和完善公司合同管理机制，并定期做好合同的分类、订卷和存档工作；',
            '处理日常诉讼纠纷。'
          ],
          requirements: [
            '全日制本科及以上学历，法律相关专业；',
            '一年以上的工作经验，熟悉法律条款，具备危机公关能力；',
            '具备开放积极的心态，责任心强，有一定的抗压能力',
          ]
        },
        {
          title: '电气主管',
          info: '负责公司电气相关产品技术标准、产品图纸、检验规范、装配工艺等技术资料的制定',
          show: false,
          duty: [
            '负责公司电气相关产品技术标准、产品图纸、检验规范、装配工艺等技术资料的制定; ',
            '产品电气设计：包括电气图纸绘制、电气部件选型等； ',
            '负责电气常规产品的优化改进，包括：产品性能等；',
            '负责及时处理现场安装、产品质量等异常情况的原因分析及纠正和预防措施的制定；',
          ],
          requirements: [
            '本科及以上学历，3年及以上同岗位同岗位工作经验；',
            '熟悉CAD，可以独立绘制电路图优先；',
            '熟练操作办公软件；',
            '具有良好的团队协调能力和沟通能力；',
            '能适应短期出差。'
          ]
        },
        {
          title: '大数据分析师',
          info: '挖掘数据分析相关指标，对关键的指标监控和分析，对异常数据和风险问题进行预警、解读和挖掘',
          show: false,
          duty: [
            '公司业务相关的大数据挖掘、分析; ',
            '挖掘数据分析相关指标，对关键的指标监控和分析，对异常数据和风险问题进行预警、解读和挖掘； ',
            '建立基于大数据的分析模型，并通过数据分析定位问题，提出解决方案，为业务提供数据决策和数据支持；',
            '设计支持业务决策和运营的数据产品，协调业务方持续改善数据产品功能，提高决策效率；',
            '大数据平台部分算法核心程序研发'
          ],
          requirements: [
            '硕士及以上学历，计算机、统计学、数学等相关专业，全日制本科有丰富经验者亦考虑；',
            '具有数据清洗、挖掘、分析相关经验；',
            '3年以上Java开发经验；',
            '具备较强的数据驱动思维和数据分析能力，擅于从数据中发现问题和有价值的规律；',
            '熟悉SQL，自我驱动能力强，具备优秀的逻辑思维、需求管理能力；',
            '学习能力强，具备良好的问题分析和解决能力，愿意并致力于新技术研究；',
            '沟通交流能力强，乐于分享，良好的团队合作精神；',
            '工作细心，耐心，思维严谨；',
            '有较强的理解能力,沟通能力以及语言表达能力。'
          ]
        }
      ],
    }
  },
  methods: {
    getShow(val) {
      if (this.active == 0) {
        return true
      } else {
        return this.active == val
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Sociology-view1-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .Sociology-view1-content {
    width: 70%;

    .Sociology-view1-content-item {
      background: rgba(247, 247, 247, 1);
      margin: 20px 0px;

      .Sociology-view1-content-item-title {
        .text1 {
          font-size: 30px;
          font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
          font-weight: 650;
          font-style: normal;
        }

        .text2 {
          font-size: 16px;
          font-family: 'PingFangSC-Semibold', 'PingFang SC Semibold', 'PingFang SC', sans-serif;
          font-weight: 650;
          font-style: normal;
        }
      }

      .Sociology-view1-content-item-img {
        height: 20vh;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .Sociology-view1-content-item-body {
      background: rgba(247, 247, 247, 1);
      padding: 60px;
      margin-top: -18px;

      li {
        padding: 8px 0;
      }
    }
  }
  .go-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    div {
      padding: 10px 30px;
      background: #2997FF;
      color: #FFFFFF;
      border-radius: 5px;
    }
  }
}
</style>
